<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-12">
      <breadcrumbs
        :path="[
          { name: $t('edit-brand-view.clients'), url: { name: 'Clients' } },
          { name: client ? client.name : '', url: clientRoute },
          { name: $t('edit-brand-view.edit-brand') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>

      <brand-form
        id="brandForm"
        :content="brand"
        :clear="submissionEndSuccessfully"
        :is-loading="!brand"
        class="mb-64"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="submissionInProgress"
            type="submit"
            form="brandForm"
            class="w-48 mr-3"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.push(clientRoute)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import BrandForm from '@/views/Dashboard/Brands/Forms/BrandForm.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'EditBrand',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    BrandForm,
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch('brand/fetchBrandDetails', to.params['brandId']),
      VueStore.dispatch('client/fetchClientDetails', to.params['id']),
    ]);

    next();
  },
  data() {
    return {
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: {
    ...mapState('client', {
      client: 'details',
    }),
    ...mapState('brand', {
      brand: 'details',
    }),
    clientRoute() {
      if (this.client)
        return { name: 'ClientDetails', params: { id: this.client.id } };
      else return {};
    },
  },
  methods: {
    ...mapActions('brand', ['updateBrand']),
    async handleSubmission(formData) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.updateBrand({ brandId: this.brand.id, data: formData });

        this.$toast.success(this.$t('edit-brand-view.updated-successfully'));
        this.$router.push(this.clientRoute);

        this.submissionEndSuccessfully = true;
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
